.space-horizontal {
  margin-left: ms(0);
  margin-right: ms(0);
}

.hidden {
  display: none;
}

.ispace-vertical {
  padding-top: ms(0);
  padding-bottom: ms(0);
}

.space-vertical {
  margin-top: ms(0);
  margin-bottom: ms(0);
}

header {
  margin-bottom: ms(2);
}

#logo {
  width: 350 * ms(1) / 72;
  height: ms(1);
  // height: ms(1);
}

h1 {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  margin: ms(3) 0;
  // width: 100%;
  // height: ms(6);
  // line-height: 1.618em;
}

h3 {
  margin: ms(1) 0 ms(0);
}

img {
  display: block;
  width: 100%;
}

figure {
  margin: 0 0 ms(0);
  a {
    display: block;
  }
  img.vertical {
    margin: 0 auto;
    max-width: 62%;
  }
}

input {
  display: block;
  padding: ms(-1) ms(0);
  margin-bottom: ms(0);
}

@media (min-width: 400px) {
  // iphone plus-like
  .space-horizontal {
    margin-left: ms(1);
    margin-right: ms(1);
  }
  .space-vertical {
    margin-top: ms(1);
    margin-bottom: ms(1);
  }
}

@media (min-width: 450px) {
  // iphone plus-like
  .space-horizontal {
    margin-left: ms(2);
    margin-right: ms(2);
  }
  .space-vertical {
    margin-top: ms(2);
    margin-bottom: ms(2);
  }

  h1 {
    line-height: 1.618em;
  }
}

@media (min-width: 560px) {
  .gallery {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: stretch;
    figure {
      width: 50%;
      box-sizing: border-box;
      margin-bottom: ms(1);
      &:nth-child(2n+1) {
        padding-right: ms(1) / 2;
      }
      &:nth-child(2n) {
        padding-left: ms(1) / 2;
      }
    }
  }
}

@media (min-width: 700px) {
  .space-horizontal {
    margin-left: ms(3);
    margin-right: ms(3);
    max-width: ms(8);
  }
}


@media (min-width: ms(8)) {
  .space-horizontal {
    margin-left: auto;
    margin-right: auto;
    max-width: ms(8) - 2 * ms(4);
  }
  .instaxy {
    background: $blacky;
    color: lighten($gray, 40%);
    margin-left: auto;
    margin-right: auto;
    max-width: ms(8) - 2 * ms(4);
    padding-left: ms(2);
    padding-right: ms(2);
    border: ms(0) solid #fbfbfb;
    border-right-width: ms(3) + ms(1);
    border-left-width: ms(2);
    box-sizing: border-box;

    box-shadow:
      0 ms(-4) ms(-2) rgba($blacky, 0.6),
      inset 0 0 ms(-2) $blacky;
      position: relative;

      &::after {
        display: block;
        background-color: #fbfbfb;
        position: absolute;
        right: -1 * (ms(3) + ms(1));
        content: "";
        top: -1 * ms(0);
        bottom: -1 * ms(0);
        width: ms(3);
        background-image: url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23d6d6d6' fill-opacity='0.44' fill-rule='evenodd'%3E%3Cpath d='M5 0h1L0 6V5zM6 5v1H5z'/%3E%3C/g%3E%3C/svg%3E");
      }
  }
}

@media (min-width: ms(8)) and (prefers-color-scheme: dark) {
  .instaxy {
    background: #000;
  }
}

@media (min-width: 880px) {
  .jumbo {
    display: flex;
    flex: 1;
    flex-direction: row;
    max-width: 845px;
    align-items: flex-start;
    // margin-left: ms(2);
    // margin-right: ms(2);
    // align-content: flex-start;
    // justify-content: flex-start;
    picture {
      flex: 1;
      width: 50%;
      flex-basis: 0;
      margin-right: ms(1);
    }
    div {
      flex: 1;
      p {
        margin-top: 0;
      }
    }
  }

  // .gallery {
  //   margin-left: -1 * ms(4);
  //   margin-right: -1 * ms(4);
  // }
}

@media (max-height: 400px) {
  h1 {
    margin: ms(1) 0;
  }
}
