@import "normalize.css";
@import "./inter/inter.css";
@import "./node_modules/modularscale-sass/stylesheets/_modularscale.scss";
@import "./node_modules/photoswipe/dist/photoswipe.css";
@import "./node_modules/photoswipe/dist/default-skin/default-skin.css";

$modularscale: (
  base: 1rem,
  ratio: 1.618
);

$orange: #D66B1E;
$lightBlue: #91B5DC;
$lightOrange: #C88A91;
$lightBlue: #5484BC;
$blue: #577391;
$darkBlue: #416494;
$gray: #587390;
$blacky: #242C40;
$white: #fff;
$lightYellow: #D0D0C0;
$darkOlive: #565722;
$lightOlive: #DAD2AC;

.screen-hidden {
  display: none;
}

@import "./looks.scss";
@import "./layout.scss";
