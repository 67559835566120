body {
  background-color: $white;
  font-family: Inter, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  color: $blacky;
}

h1 {
  font-weight: 200;
  font-size: ms(2);
  line-height: ms(3);
}


h2 {
  font-weight: 600;
  font-size: ms(0);
  line-height: ms(1);
}

h3 {
  font-weight: 200;
  font-size: ms(1);
  line-height: ms(2);
}

a {
  color: $darkBlue;
}

strong {
  font-weight: 600;
}

p, ol {
  line-height: ms(1);
}

.green-inverted {
  background: $darkOlive;
  color: $lightOlive;
}

.note {
  font-size: ms(-1);
  line-height: ms(0);
  color: grayscale($gray);
  a {
    color: grayscale($darkBlue);
  }
}

.text-spacing {
  padding: ms(-3) ms(-4);
  margin: -1 * ms(-3) -1 * ms(-4) + ms(-5);
}

input {
  border: ms(-5) solid $darkBlue;
  border-radius: ms(-2);
  line-height: ms(1);
  &:focus {
    outline: none;
    box-shadow: 0 0 0 ms(-3) rgba($lightBlue, 0.3);
  }
}

input[type=email] {
  width: 100%;
  box-sizing: border-box;
}

input[type=submit] {
  background: $darkBlue;
  color: $white;
  cursor: pointer;
  border: none;
  font-weight: 600;
  &:hover {
    background: lighten($color: $darkBlue, $amount: 7);
  }
}

#overlay {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba($darkOlive, 0.9);
  color: $white;
  text-align: center;
  transition: opacity 300ms;
  opacity: 0;
  pointer-events: none;
  &.visible {
    pointer-events: auto;
    opacity: 1;
  }
}
header a {
  text-decoration: none;
  border: none;
}
#logo {
  text-indent: -999em;
  background-image: url('logo.svg');
  background-repeat: no-repeat;
  // background-size: cover;
}

@media (prefers-color-scheme: dark) {
  body {
    background: $blacky;
    color: $white;
  }
  a {
    color: lighten($darkBlue, 40%);
  }
  input[type=submit] {
    background: lighten($color: $darkBlue, $amount: 7);
  &:hover {
    background: $darkBlue;
  }
  }
  .note {
    color: lighten(grayscale($gray), 25%);
    a {
      color: lighten(grayscale($darkBlue), 25%);
    // color: grayscale($darkBlue);
  }
  }
  #logo {
    background-image: url('logo-white.svg');
  }
  .green-inverted {
    color: lighten($lightOlive, 10%);
  }
}
